<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span
          ><v-divider class="mx-4" inset vertical></v-divider>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="isEdit">
                <v-text-field
                  v-model="item.TBLId"
                  label="รหัส"
                  dense
                  required
                  readonly
                  ref="TBLId"
                  @keydown.enter="NextFocus('TBLName')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.TBLName"
                  label="ชื่อ"
                  dense
                  required
                  ref="TBLName"
                  @keydown.enter="NextFocus('TBLTypeId')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="item.TBLTypeId"
                  :items="tblTypeList"
                  item-value="TBLTypeId"
                  item-text="TBLTypeName"
                  :return-object="false"
                  dense
                  label="ประเภทโต๊ะ"
                  ref="TBLTypeId"
                  @change="NextFocus('ZoneId')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="item.ZoneId"
                  :items="zoneList"
                  item-value="ZoneId"
                  item-text="ZoneName"
                  :return-object="false"
                  dense
                  label="พื้นที่"
                  ref="ZoneId"
                  @change="NextFocus('Status')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" v-if="isEdit">
                <v-autocomplete
                  v-model="item.Status"
                  :items="enumStatus"
                  item-value="id"
                  item-text="name"
                  :return-object="false"
                  dense
                  label="สถานะ"
                  ref="Status"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" @click="cancel"> ยกเลิก </v-btn>
          <v-btn class="primary" @click="submit"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseEnum from "@js/enum";
import { mapActions, mapState } from "vuex";
export default {
  props: ["modal", "item", "itemIndex"],
  data() {
    return {
      enumStatus: baseEnum.status.data,
      dialog: false,
      itemEdit: {},
      indexEdit: -1,
      tblTypeList: [],
      zoneList: [],
    };
  },
  mounted() {
    this.getTblTypeList();
    this.getZoneList();
  },
  computed: {
    ...mapState("auth", ["user"]),
    dialogTitle() {
      return this.indexEdit == -1 ? "เพิ่ม" : "แก้ไข";
    },
    isEdit() {
      return this.indexEdit == -1 ? false : true;
    },
  },
  methods: {
    ...mapActions("settings", [
      "actTblCreate",
      "actTblUpdate",
      "actTblTypeGetsUse",
      "actZoneGets",
    ]),
    closeModal() {
      this.$emit("close", false);
    },
    cancel() {
      this.closeModal();
    },
    getTblTypeList() {
      this.actTblTypeGetsUse().then(({ status, data }) => {
        if (status == 200 && data.success) {
          this.tblTypeList = data.val;
          console.log(data.val);
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.message);
        }
      });
    },
    getZoneList() {
      let whereStatus = { Status: "1" };
      this.actZoneGets(whereStatus).then(({ status, data }) => {
        if (status == 200 && data.status == "success") {
          this.zoneList = data.result;
          console.log(data.result);
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.message);
        }
      });
    },
    submit() {
      if (this.indexEdit == -1) {
        //เพิ่ม
        this.itemEdit.CreatedBy = this.user.UserId;
        this.itemEdit.ModifiedBy = this.user.UserId;
        this.actTblCreate(this.itemEdit).then(({ status, data }) => {
          if (status == 200 && data.success) {
            this.closeModal();
          } else {
            this.Error(data.message);
          }
        });
      } else {
        //แก้ไข
        this.itemEdit.ModifiedBy = this.user.UserId;
        this.actTblUpdate(this.itemEdit).then(({ status, data }) => {
          if (status == 200 && data.success) {
            this.closeModal();
          } else {
            this.Error(data.message);
          }
        });
      }
    },
  },
  watch: {
    modal() {
      this.dialog = this.modal;
    },
    item() {
      this.itemEdit = this.item;
    },
    itemIndex() {
      this.indexEdit = this.itemIndex;
    },
  },
};
</script>

<style></style>
